<template>
  <v-card :loading="loading">
    <v-card-title>
      <span class="headline"></span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col>
            <v-autocomplete
                :items="sites"
                item-text="name"
                label="Sites"
                v-model="site"
                return-object
                @change="onSiteChange"
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-dialog v-model="confirmDialog" v-if="confirmDialog"
          persistent
          max-width="500"
          content-class="confirm-dialog">
          <v-card>
            <v-card-title class="headline primary">
               Save?
            </v-card-title>
            <v-card-text>
              Are you sure you want to save these changes?
            </v-card-text>
            <v-card-actions>
               <v-spacer></v-spacer>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(false)"> No</v-btn>
               <v-btn color="blue darken-1" text @click="confirmDialogCallback(true)"> Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-form ref="configForm">
          <div v-if="site !== null && site.siteId !== null">
            <v-row>
              <v-col cols="12">
                <v-checkbox v-model="site.doNotCallPatients" label="Do Not Call Patients"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="site.offloadingCallsOnly" label="Offloading Calls Only"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="site.receiveEscalations" label="Receive Escalations"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        prepend-icon="mdi-thermometer"
                        v-model.number="site.temperatureThresholdOverride"
                        :rules="tempThresholdRules"
                        label="Temperature Threshold Override" type="number"
                        @input="($value) => (site.temperatureThresholdOverride = parseFloat($value) || null)"></v-text-field>
                  </template>
                  <span>Only provide a value if the override is different than the current default of 2.22</span>
                </v-tooltip>


              </v-col>
              <v-col cols="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-bind="attrs"
                                  v-model.number="site.reengagementDaysOverride"
                                  v-on="on"
                                  :rules="reengagementDaysOverrideRules"
                                  label="Reengagement Days Override"
                                  prepend-icon="mdi-counter" type="number"
                                  @input="($value) => (site.reengagementDaysOverride = parseFloat($value) || null)"></v-text-field>
                  </template>
                  <span>Only provide a value if the override is different than the current default of 4</span>
                </v-tooltip>


              </v-col>
            </v-row>

          </div>
          <div v-if="customer !== null && site !== null && site.siteId === null">
            <v-row>
              <v-col>
                <v-checkbox v-model="customer.doNotCallPatients" label="Do Not Call Patients"></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="onCancel"> Cancel </v-btn>
      <v-btn color="blue darken-1" text @click="onSave"> Save </v-btn>
    </v-card-actions>
  </v-card>

</template>

<script>

import SiteService from "@/services/SiteService";
import CustomerMasterService from "@/services/CustomerMasterService";
import 'vuetify-dialog/dist/vuetify-dialog.css'

export default {
  name: "CustomerConfig",
  props: {
    customerId: Number
  },
  components: {
  },
  data: () => ({
    loading: false,
    confirmDialog: false,
    site: null,
    initialCustomer: null,
    customer: null,
    initialSites: [
    ],
    sites: [
    ],
    tempThresholdRules: [
      v => ( !v || v <= 9.99 ) || "Temperature Threshold Override should be under 9.99",
      v => ( !v || v >= 0 ) || "Temperature Threshold Override should be greater than 0",
      v => ( !v || v !== 2.22 ) || "This is currently the default no override is needed"
    ],
    reengagementDaysOverrideRules: [
      v => ( !v || v <= 90 ) || "Reengagement Days Override should be under 999",
      v => ( !v || v >= 0 ) || "Reengagement Days Override should be greater than 0",
      v => ( !v || v !== 4 ) || "This is currently the default no override is needed"
    ]
  }),
  async created() {
    await this.loadData();
    this.site = this.sites[0];
   },
  mounted() {
    // 
  },
  methods: {
    onSiteChange() {
      //
    },
    async confirmDialogCallback (agreed) {
      this.confirmDialog = false;

      if (!this.$refs.configForm.validate()) {
        await this.$dialog.error({
          text: "Unable to save.",
          title: "Error",
          actions: ['Okay']
        }); 
        return;
      }
    
      if (!agreed) return;
      try {
        this.loading = true;
        if (this.site.siteId === null) {
          await new CustomerMasterService().put(this.customerId, this.customer);
        } else {
          await new SiteService().put(this.customerId, this.site);
        }

        await this.loadData();
        this.loading = false;
        await this.$dialog.info({
          text: "Configuration was saved successfully.",
          title: "Saved",
          actions: ['Okay']
        });
        this.$emit("saved");
      } catch (e) {
        console.log(e);
        await this.$dialog.error({
          text: "Unable to save.",
          title: "Error",
          actions: ['Okay']
        }); 
      } finally {
        this.loading = false;
      }
    },
    async loadData() {
      this.loading = true;
      let promises = [];

      promises.push(new SiteService()
          .getSites(this.customerId)
          .then((resp) => {
            this.sites.push({
              name: "Customer",
              siteId: null,
            });
            
            resp.forEach(s => {
              this.sites.push(s);
            });
            
            this.initialSites.splice(0);
            this.sites.forEach(s => {
              this.initialSites.push(Object.assign({}, s));
            });
          })
          .catch((err) => {
            console.log(err);
          }));

      promises.push(new CustomerMasterService()
          .get(this.customerId)
          .then((customer) => {
            this.customer = customer;
            this.initialCustomer = Object.assign({}, this.customer);
          })
          .catch((err) => {
            console.log(err);
          }));



      await Promise.allSettled(promises);
      this.loading = false;
    },
    async onSave() {
      this.confirmDialog = true;
    },
    onCancel() {
      this.resetState();
      this.$emit("cancelled");
    },
    resetState() {
      this.sites.splice(0);
      this.sites.push({
        name: "Customer",
        siteId: null,
      });
      this.site = this.sites[0];
      if (this.initialSites) {
        this.initialSites.forEach(s => {
          this.sites.push(Object.assign({}, s));
          this.customer = Object.assign({}, this.initialCustomer);
        });
      }
    }
  }
}
</script>

<style scoped>
  .confirm-dialog .v-card__title {
    color: #FFFFFF;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
  }

  .confirm-dialog .v-card__text {
    margin-top: 26px;
    font-size: 1rem !important;
  }
</style>