<template v-slot:top>
  <v-container>
    <v-dialog v-model="configDialog" max-width="800px" persistent v-if="configDialog">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="configDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edit Configuration</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn dark text @click="configDialog = false"> Close </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <CustomerConfig @saved="configDialog = false" @cancelled="configDialog = false" v-if="configDialog"  :customerId="selectedCustomerId"/>
    </v-dialog>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col md="4"> Commerical </v-col>
          <v-col offset-md="6" md="2">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="customers"
        sort-by="name"
        sort-desc
        class="elevation-1"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loading"
      >
        <template v-slot:top>
          <v-dialog v-model="dialog" max-width="750px" v-if="dialog">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.name"
                        label="Name"
                        solo
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="10">
                      <v-textarea
                        outlined
                        name="note"
                        label="Note"
                      ></v-textarea>
                    </v-col>                    
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-icon v-if="hasAccess('EditCustomerWorklistItemCadence')" medium class="mr-2" @click="onEditConfig(item.customerId)">
            mdi-cog-outline
          </v-icon>
        </template>
        <template #[`item.isActive`]="{ item }">          
            {{item.isActive === true?"Yes":"No"}}         
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CustomerMasterService from '../services/CustomerMasterService';
import CustomerConfig from "@/components/CustomerConfig";
import Security from "../mixins/security";

export default ({
  mixins: [Security],
  components: {
    CustomerConfig
  },
  data: () => ({
    search: "",
    loading:true,
    dialog: false,
    itemsPerPage:5,
    configDialog: false,
    selectedCustomerId: 0,
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
      },
      { text: "Active", value: "isActive" },
      { text: "Actions", value: "actions", sortable: false, align: "right"},
    ],
    customers: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      active: "",
    },
    defaultItem: {
      name: "",
      active: "",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Customer" : "Edit Customer";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.loading = true;
          new CustomerMasterService().getCommericalCustomer().then((value) => this.customers = value, this.loading = false);
    },

    onEditConfig (id) {
      this.selectedCustomerId = id;
      this.configDialog = true;
    },
    editItem(item) {
      this.editedIndex = this.customers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.customers[this.editedIndex], this.editedItem);
      } else {
        this.customers.push(this.editedItem);
      }
      this.close();
    },
  },
});
</script>