<template>
  <v-main>
    <CommercialCustomers/>
    <VACustomers/>   
  </v-main>
</template>
<script>
import VACustomers from '../components/VACustomers.vue';
import CommercialCustomers from '../components/CommercialCustomers.vue';
export default {
    components: { 
    VACustomers,
    CommercialCustomers
  },
}
</script>
